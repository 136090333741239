import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import Cooperation from "../../components/Cooperation";
import HomeInfoBoxes from "../../components/HomeInfoBoxes";
import MediaLogos from "../../components/MediaLogos";

const Wohnkredit = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Mit miracl zur Traumimmobilie: Dein individueller Wohnkredit" showCalc={true} />
            <Cooperation hasGreyBg hasMarginTop />
            <HomeInfoBoxes hasMarginTop />
            <MediaLogos hasGreyBg hasMarginTop />
            <Article hasMarginTop>
                <p>
                    Für den Traum vom Eigenheim braucht es nur noch die richtige Finanzierung? Dann kommt der
                    maßgeschneiderte Kredit genau richtig.
                </p>
                <p>
                    Fast jeder ist seit Jahren Kunde bei einer Bank, fast jeder geht davon aus, ohne weiteres auch einen
                    Kredit bei dieser Bank zu bekommen. Tatsächlich ist es aber nicht immer die eigene Hausbank, die den
                    besten Ratenkredit für deine Situation bereit hält. Oft will die Bank einen bestimmten Zinssatz, oft
                    will man nicht unter einen bestimmten monatlichen Wert was die Rückzahlungen betrifft und fast immer
                    fehlt ein wichtiger Faktor beim gesamten Unterfangen: Der Vergleich.
                </p>
                <p>
                    Erst mit einem unabhängigen Vergleich unterschiedlicher Angebote für den Wohnkredit ist der Weg zur
                    Eigenheimfinanzierung wirklich geebnet. Unabhängig von Parametern wie Zins oder Laufzeit sind es oft
                    die Gespräche mit den Experten selbst, die Möglichkeiten aufzeigen und schon bei der Vorbereitung
                    zur Antragstellung Sparpotential aufzeigen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Das richtige Konzept macht den Unterschied</h2>
                <p>
                    Die eigene Bank ist sicherlich kein schlechter Beginn für die Recherche des eigenen Wohnkredits,
                    aber sie sollte nicht die einzige Station sein. Egal ob Neufinanzierung oder Umschuldung, jede Bank
                    hat ihre eigenen Geschäftsmodelle, ihre eigene Geschäftspolitik. Selbst bei jahrelangen
                    Geschäftsbeziehungen stehen dem angehenden Kreditnehmer oder der Kreditnehmerin nicht immer alle
                    Optionen zur Verfügung. Auch wer versucht, ausschließlich online selbst Kredite zu recherchieren,
                    wird deswegen nicht zwangsläufig Erfolg haben. Genau hier setzt miracl an. Denn wir verbinden smarte
                    Technologien des Internets von heute mit dem Know-How und der Erfahrung von Experten. Kostenlos,
                    österreichweit und immer im Sinne der Kreditnehmer.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wie komme ich zu einem günstigen Kredit?</h2>
                <p>
                    Den wichtigsten Schritt hast du bereits getan, denn du bist bei uns, um dich über deine
                    Möglichkeiten zu informieren und Angebote für einen Wohnkredit oder ein Darlehen zu vergleichen. Wir
                    bieten dir nicht einfach irgendeinen Online-Kredit, sondern vergleichen die Angebote von mehr als
                    100 Banken und Kreditinstituten um dir eine maßgeschneiderte Finanzierung für dein Traumprojekt
                    anbieten zu können. Unverbindlich. Kostenlos. Einfach.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wie günstig sind Kredite?</h2>
                <p>
                    Das hängt von ein paar Faktoren ab. Wieviel Geld benötigst du? Wieviel Eigenkapital ist vorhanden?
                    Wie lange soll denn die Laufzeit deines Wohnkredits betragen? Wie sieht es mit deiner{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    aus – zahlst du vielleicht bereits andere Kredite zurück? Schon hier ist jede noch so kleine
                    Information wichtig und kann lange vor einem Kreditantrag die Weichen stellen. Das Team von miracl
                    hilft dir gerne dabei, nicht nur die richtigen Antworten auf diese Fragen zu finden, sondern den
                    Antworten entsprechend auch wirklich ein individuelles Angebot für dich zusammenzustellen. Unser
                    Ziel ist es, den besten Wohnkredit für deine ganz individuelle Situation zu finden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wie funktioniert ein Kreditvergleich?</h2>
                <p>
                    Einfach ausgedrückt, vergleichen wir die Kreditangebote jeder einzelnen Bank und jedes einzelnen
                    Kreditinstituts. Dinge wie effektiver Jahreszins oder monatliche Raten sind da nur die Spitze des
                    Eisbergs und können schnell vom Gesamtbild ablenken.
                </p>
                <p>
                    Nicht immer sind die Angebote der Hausbank dabei die besten. Zwar ist es durchaus möglich, dass du
                    als jahrelanger Kunde gewisse Vorteile genießt, dies ist aber bei Kreditgeschäften nicht immer der
                    Fall. Banken verfolgen ihre eigenen Geschäftsmodelle und der Kreditmarkt ist ein äußerst
                    wechselhafter. Da den Überblick zu behalten, ist selbst für Experten nicht einfach. Spielräume
                    auszunutzen, günstigere{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    auszuloten oder gar online verlässliche Informationen zu erhalten, ist ein zeitintensiver Prozess
                    der für Personen ohne Vorkenntnisse voller Tücken scheint. Hier macht sich ein Vergleich sehr
                    schnell bezahlt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Welcher Kreditvergleich ist der beste für mich?</h2>
                <p>
                    Der beste Kreditvergleich ist der, der deine Bedürfnisse miteinbezieht. Denn ein Wohnkredit ist
                    nicht einfach irgendein Kredit. Es gibt so etwas wie “einfach irgendein Kredit” nicht einmal. Jede
                    Person hat unterschiedliche Bedürfnisse. Deswegen verlassen wir uns bei miracl auf das Wissen und
                    die jahrzehntelange Erfahrung unserer Finanzexperten und auf die neuesten digitalen Innovationen wie
                    unseren{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    . Ein guter Kreditvergleich zeigt dir im Rahmen der persönlichen Beratung deine besten Optionen auf.
                    miracl geht es nicht bloß um schnelle Abwicklung – wir wollen, dass du jeden Aspekt deines
                    Wohnkredits verstehst und dich bei deinem Projekt so richtig wohl fühlst!
                </p>
            </Article>

            <BreadcrumbList page={"wohnkredit"}></BreadcrumbList>
            <ArticleStructuredData
                page={"wohnkredit"}
                heading={"Mit miracl zur Traumimmobilie: Dein individueller Wohnkredit"}
            />
        </Layout>
    );
};

export default Wohnkredit;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "mortgageCalc", "page.artikel.wohnkredit"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
